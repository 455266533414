import { Link } from '@remix-run/react';
import LayoutPaddingX from '../layout/LayoutPaddingX';
import background from '../../images/background-no-ellipses.webp';
import logo from '../../images/logo-wide.svg';
import brokenCard from '../../images/broken-card.png';
import MainFooter from '../layout/MainFooter';

export default function NotFoundErrorBoundary({
  hasHomePageLinks = true,
}: {
  hasHomePageLinks?: boolean;
}) {
  return (
    <div className="flex min-h-screen w-screen flex-col">
      <LayoutPaddingX className="bg-white pb-8 shadow-md">
        <header className="flex flex-col pt-8 sm:pt-10">
          <nav className="flex items-center justify-between">
            <div>
              {hasHomePageLinks ? (
                <Link to="/">
                  <img src={logo} alt="cardonomics logo" />
                </Link>
              ) : (
                <img
                  src={logo}
                  alt="cardonomics logo"
                  className="cursor-not-allowed"
                />
              )}
            </div>
          </nav>
        </header>
      </LayoutPaddingX>
      <div className="flex w-full flex-grow items-center justify-center p-5">
        <div className="absolute left-0 right-0 top-0 z-[-1] h-full w-full overflow-hidden">
          <img
            src={background}
            alt="background"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="flex max-w-full flex-col items-center gap-8 overflow-auto">
          <h1 className="text-center text-6xl">404</h1>
          <p className="text-2xl">
            The page you are looking for can't be found.
          </p>
          <img
            src={brokenCard}
            alt="broken card"
            className="w-[350px]"
            width={350}
          />
          {hasHomePageLinks && (
            <p className="text-2xl">
              Go to <Link to="/">homepage</Link>
            </p>
          )}
        </div>
      </div>
      <MainFooter />
    </div>
  );
}
